<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :show-operations="false"
      :resource-limit="100"
      :buttons="computedButtons"
      :resource-query="resourceQuery"
      resource="analytics"
      :custom-resource="soldCartItemsResource"
      :transform-fetched-data="transformFetchedData"
      transform-fetched-meta-allowed
      no-meta-check
      headers-filterable
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :locale="locale"
      show-filter
      :show-summary="false"
      :search-filters="filtersList"
      route-base="/reports/statistics/sold_cart_items"
      do-route-filters
      prune-search-filters
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import pick from 'just-pick'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'
import { useExportsStore } from '@/store/exports'

const headersConfigPath =
  'settings.headerFilters.reports.statistics.sold_cart_items'

export default {
  name: 'ReportsStatisticsSoldCartItems',
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.sold_cart_items.title')
    }
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'register',
      'branch_group'
    ])

    this.$emit('route-filter', {
      ...elegibleObj,
      register: elegibleObj.register || undefined
    })

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      product: undefined,
      soldCartItemsResource: th.analyticsHandlers().analytics.reports
        .AnalyticsReportsTransactionsItems,
      // NOTE: the header list is quite sensitive to adding formatters. We discovered perfomance issues in massive data scenerios that are
      // are adressed with .transformFetchedData in this component
      headers: [
        {
          field: '_date_label',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.date'
          ),
          fallback: '--',
          minWidth: 160,
          truncate: true,
          sortType: 'date'
        },
        {
          field: 'product_custom_id',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.cart_item_number'
          ),
          align: 'right',
          minWidth: 160,
          fallback: '--',
          truncate: true
        },
        {
          label: this.$t('common.headers.name.title'),
          field: 'name',
          truncate: true,
          fallback: '-',
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.products.manufacturer'),
          field: 'manufacturer',
          truncate: true,
          fallback: '-',
          minWidth: 180
        },
        {
          field: 'qty',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.qty'
          ),
          align: 'right',
          fallback: '--',
          minWidth: 160,
          truncate: true,
          sortType: 'number'
        },
        {
          field: 'default_purchase_price',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.purchase_price'
          ),
          align: 'right',
          fallback: '--',
          minWidth: 160,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.default_purchase_price)) {
              return this.$formatCurrency(
                row.default_purchase_price,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'product_selling_price',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.selling_price'
          ),
          align: 'right',
          fallback: '--',
          minWidth: 160,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.product_selling_price)) {
              return this.$formatCurrency(
                row.product_selling_price,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'amount_total_gross',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.selling_price_total'
          ),
          fallback: '--',
          minWidth: 190,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.amount_total_gross)) {
              return this.$formatCurrency(row.amount_total_gross, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'discount_amount_total',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.discount_total'
          ),
          fallback: '--',
          minWidth: 160,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            if (Number.isFinite(row.discount_amount_total)) {
              return this.$formatCurrency(
                row.discount_amount_total,
                row.currency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'vat_amount_total',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.vat_amount'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.vat_amount_total)) {
              return this.$formatCurrency(row.vat_amount_total, row.currency)
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          field: 'balance_custom_id',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.balance_number'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true
        },
        {
          field: 'salesman_custom_id',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.salesman_number'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true
        },
        {
          field: 'cashier_custom_id',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.cashier_number'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true
        },
        {
          field: 'transaction_register_custom_id',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.register_number'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true
        },
        {
          field: 'transaction_branch_custom_id',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.branch_number'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true
        }
      ],
      headersDefaultHide: [
        'vat_amount_total',
        'balance_custom_id',
        'salesman_custom_id',
        'cashier_custom_id',
        'transaction_register_custom_id',
        'transaction_branch_custom_id',
        'manufacturer'
      ],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:sold_cart_items:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: ({ handleDownload, resourceOptions }) => {
            this.handleExport({ handleDownload, resourceOptions })
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      localConfiguration: 'Config/getLocalConfiguration',
      currentLocation: 'Config/getCurrentLocation',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    headersConfig() {
      return safeGet(this.localConfiguration, headersConfigPath) || {}
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    filtersList() {
      return [
        {
          name: 'register',
          type: 'remote-search-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.filters.register'
          ),
          resource: 'registers',
          computeName: this.$formatRegister,
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'balance_custom_id',
          type: 'input',
          label: this.$t('pages.reports.sold_cart_items.filters.balance_number')
        },
        {
          name: 'product',
          type: 'remote-search-select',
          label: this.$t(
            'pages.reports.statistics.sold_cart_items.filters.product'
          ),
          resource: 'products',
          computedFields: ['custom_id', 'name'],
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            original_product: true
          })
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['date_start', 'date_end'],
          type: 'daterange',
          dateTimeMode: true,

          label: this.$t(
            'pages.reports.statistics.sold_cart_items.headers.date'
          ),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            date_start: filterObject.start,
            date_end: filterObject.end
          })
        }
      ]
    },
    resourceQuery() {
      return {
        query: {
          register_custom_id: this.parsedQuery.register_custom_id || undefined,
          register: this.parsedQuery.register || undefined,
          branch: this.currentLocation || undefined,
          branch_group: this.parsedQuery.branch_group || undefined,
          limit: 100
        }
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  watch: {
    'parsedQuery.product': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.fetchProduct(newValue)
        }
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },

  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.reports.statistics.sold_cart_items.title')
        })
      })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    // NOTE: this is a perfomance optmisation in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    transformFetchedData(data) {
      return data.map((item) => {
        if (!item.date) {
          item._date_label = '--'
        } else {
          item._date_label = this.$date.formatDateTimeWithTimezone(item.date)
        }

        return item
      })
    },
    async fetchProduct(product) {
      try {
        const { data } = await th.products().get(product)
        this.product = data
      } catch (error) {
        this.$logException(error, {
          trackError: false
        })
      }
    },
    async handleExport({ resourceOptions }) {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...resourceOptions.query,
        limit: undefined,
        format: 'csv'
      }

      try {
        const {
          data
        } = await th
          .analyticsHandlers()
          .analytics.reports.AnalyticsReportsTransactionsItems.export(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.sold_cart_items.title',
            date: new Date(),
            action: {
              entity: 'analyticsHandlers',
              path: 'analytics.reports.AnalyticsReportsTransactionsItems',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.sold_cart_items.title')
          })
        })
      }
    }
  }
}
</script>
